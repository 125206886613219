.comp5subOrg-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  /* height: 80px; */
  gap: 5%;
  margin-top: 5%;
}
.subcorouselorg-main{
  height: 200px;
}

#orgImage {
  width: 10%;
  border-radius: 50px;
}

#comp5sub-text {
  font-size: 14px;
}

