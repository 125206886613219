/* .mainCoursel {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.coursel-Main {
  /* background-image: url(../../Assets/cBackground1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  border-radius: 10px;
  width: 100%;

}


.carousel__dot-group {
  text-align: center;
}

.carousel__dot-group button {
  height: 15px !important;
  width: 15px !important;
  transform: scale(0.5);

  border-radius: 29px;
  border: none;
}

.carousel__dot--selected {
  background-color: #009ae0;
}
