@font-face {
  font-family: "circular";
  src: local("circular"), url("./fonts/CircularStd-Book.otf") format("opentype");
}

@font-face {
  font-family: "circularMedium";
  src: local("circular"),
    url("./fonts/CircularStd-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: circular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  padding: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
