.subcoursel {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  border-radius: 10px;
  padding: 5%;
  height: 160px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#coursel-image {
  width: 90%;
}

.sub-left button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6%;
  border-radius: 5px;
  border: none;
  font-size: 14;
  font-weight: bold;
  height: 36px;
  width: 125px;
  margin-bottom: 5%;
  background-color: white;
}

.sub-left {
  width: 80%;
}

.sub-left > p{
  width: 90%;
}

.sub-right{
  width: 40%;
}

#iframe {
  padding: 10% !important;
  background-color: white !important;

  height: 200px;
  width: 100%;
  position: relative;
}

#cross {
  position: absolute;
  width: 8%;
  right: 5px;
  top: 5px;
}
