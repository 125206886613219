#bottom-cta {
  background-color: #009ae0;
  width: 94%;
  border-radius: 5px;
  border: none;
  /* position: fixed;
  bottom: 0px; */
  color: white;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}

.bottom-div {
  height: 50px;
  padding-top: 3%;
  left: 0;
  text-align: center;
  background-color: white;
  width: 100%;
  border-top: 0.5px solid #e5e5e5;
  height: 50px;
  position: fixed;
  bottom: 0px;
  height: 70px;
}
