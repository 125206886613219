.subcourselText {
  box-sizing: border-box;
  width: 99%;
  border-radius: 6px;
  border: 0.5px solid #bdbdbd;
  padding-left: 2%;
  padding-right: 4%;
  /* height: 800px !important; */
}

.text1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3%;
  margin-top: 4%;
  margin-bottom: 4%;
}

.text1 > * {
  margin:1%;
}

#key {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  border-radius: 25px;
  background-color: #1db794;
  width: 20px;
  height: 20px;
  color: white;
  margin-top: 2%;
}

#para {
  width: 90%;
}
