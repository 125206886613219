.component-2 {
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
 
.comp2-card {
  width: 100%;
  border: 0.5px solid #bdbdbd;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  border-radius: 6px;
  padding-top: 5%;
}

.card-top {
  display: flex;
  flex-direction: row;
  gap: 20%;
  align-items: center;
  justify-content: center;
}

.com2-slider {
  width: 90%;
  background-color: #f1f1f1;
  height: 10px;
  margin-top: 5%;
  border-radius: 5px;
  position: relative;
}
#slider {
  position: absolute;
  left: 0;
  top: -3px;
}

.green-rectangle {
  background-color: #009976;
  height: 10px;
  max-width: 93% !important;
  /* border-radius: 30px; */
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.green-circle {
  border-radius: 50px;
  width: 5px;
  height: 5px;
  /* margin-left: 75vw; */
  z-index: 1;
  max-width: 90% !important;
  background-color: #009976;
}

#trophie {
  position: absolute;
  right: 0;
  top: -5px;
  width: 6%;
}

.yourApp,
.yourPerformance {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.yourPerformance {
  width: 25% !important;
}

.yourApp p,
.yourPerformance p {
  text-align: center;
  width: 100px;
}

#vs {
  width: 30px;
}

.card-bottom {
  background-color: #fafafa;
  color: black;
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  border-top: 0.05px solid #e5e5e5;
}

.sub{
  transform: translateY(-3px);
}