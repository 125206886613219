.comp3-main {
  width: 100%;
  margin-top: 5%;
  font-size: 14px;
}

.comp3-main .carousel {
  box-sizing: border-box;
  padding-top: 4%;
}

.comp3-head{
  width: 90%;
  margin-top: 5%;
}

/* .carousel {
  padding: 3%;
} */ 

.carousel__dot-group {
  text-align: center;
}

.carousel__dot-group button {
  height: 15px !important;
  width: 15px !important;
  transform: scale(0.5);

  border-radius: 29px;
  border: none;
}

.carousel__dot--selected {
  background-color: #009ae0;
}

#heading{
  font-size: 20px;
  font-weight: 700;
}

#sub-heading{
  font-size: 16px;
  margin-top: 2px;
  color:rgb(0,0,0,0.6);
}