.comp5-main {
  margin-top: 5%;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  /* margin-bottom: 70px; */
}

#heading {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10%;
  margin-bottom: 1%;
  width: 90%;
}

/* #sub-heading {
  font-size: 16px;
  font-weight: normal;
  margin-top: 3%;
  opacity: 0.6;
} */
