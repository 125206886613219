.component4 {
  width: 100%;
  border: 1px solid #049bb2;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f6feff;
  margin-top: 5%;
  padding-bottom: 5%;
}

.comp4-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5%;
  padding-top: 5%;
}

.comp4-btn button {
  background-color: #049bb2;
  color: white;
  height: 36px;
  width: 120px;
  border-radius: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: 5%;
  font-size: 10px;
  font-weight: 600;
  gap: 4%;
}

.comp4-left {
  width: 70%;
  margin-left: 3%;
  margin-top: 5%;
}

#comp4-head {
  font-size: 14px;
  font-weight: bold;
}

a {
  text-decoration: none !important ;
}
